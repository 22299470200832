import axios from '../api/axios';
import config from '../config';

const API_URL = '/students';

const createStudent = (data) => {
  return axios.post(config.API_URL + API_URL, data, { headers: {'Content-Type': 'application/json' }});
};

const studentService = {
  createStudent,
};

export default studentService;
