import React from 'react'

const Global = () => {
    return (
<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_1_4654)">
<path d="M4.64502 0.993945V0.110352C3.12752 0.254727 1.80706 1.08723 1.00127 2.2916C0.856058 2.50925 0.728365 2.7381 0.619399 2.97598C0.376496 3.50356 0.229361 4.07015 0.184868 4.64926H0.169556V5.39691H0.184868C0.229361 5.97603 0.376496 6.54261 0.619399 7.0702C0.728399 7.30806 0.856091 7.5369 1.00127 7.75457C1.80706 8.95894 3.12752 9.79144 4.64502 9.93582V9.05223C4.33956 8.8766 4.04706 8.53676 3.80346 8.0616C3.66909 7.79894 3.55377 7.50707 3.46049 7.19394C3.85382 7.14667 4.24905 7.11685 4.64502 7.10457V6.33316C4.18923 6.34659 3.73431 6.38127 3.28174 6.43707C3.22152 6.09319 3.18399 5.74572 3.1694 5.39691H4.64487V4.64926H3.1694C3.18471 4.29113 3.22284 3.94207 3.28174 3.6091C3.72159 3.6641 4.1769 3.69848 4.64502 3.71301V2.9416C4.24905 2.92933 3.85382 2.89951 3.46049 2.85223C3.54792 2.55308 3.66272 2.26264 3.80346 1.98457C4.04706 1.50941 4.33956 1.16879 4.64502 0.993945ZM1.35487 3.2202C1.73848 3.33081 2.12747 3.42182 2.52034 3.49285C2.45436 3.87518 2.41354 4.26143 2.39815 4.6491H0.957681C1.0032 4.15281 1.13774 3.66878 1.35487 3.2202ZM1.35487 6.82582C1.13774 6.37723 1.0032 5.8932 0.957681 5.39691H2.39815C2.41502 5.79863 2.45612 6.18582 2.52034 6.55238C2.12752 6.62397 1.73854 6.71523 1.35487 6.82582ZM3.33831 8.79004C2.72076 8.4984 2.18384 8.06028 1.77424 7.51379C2.07741 7.43346 2.38364 7.36518 2.69221 7.3091C2.85331 7.87894 3.07252 8.38004 3.33831 8.79004ZM2.69221 2.73676C2.38362 2.68076 2.07739 2.61247 1.77424 2.53207C2.18388 1.98562 2.72079 1.54751 3.33831 1.25582C3.07252 1.6652 2.85331 2.16707 2.69221 2.73676Z" fill="black"/>
<path d="M9.14127 5.26898C9.10174 4.71008 9.21565 4.1582 9.45362 3.75508C9.52957 3.62501 9.62411 3.50673 9.73424 3.40398C9.78081 3.35977 9.84127 3.2893 9.82877 3.18383C9.82487 3.14352 9.81096 3.1032 9.78534 3.05586C9.60619 2.73106 9.42379 2.40808 9.23815 2.08695C9.16909 1.96602 9.07299 1.91945 8.94596 1.94352C8.32737 2.06211 7.75377 1.94977 7.19174 1.59852C6.62049 1.24117 6.27627 0.824141 6.10799 0.286172C6.06221 0.141953 5.97002 0.0730469 5.81815 0.0707031C5.69174 0.0691406 5.56471 0.0675781 5.43831 0.0667969L5.36393 0.0660156C5.32596 0.0660156 5.28721 0.0652344 5.24768 0.0644531V2.24258C5.75534 2.24805 6.2569 2.39758 6.70659 2.68133C7.94534 3.46273 8.33206 5.08898 7.58799 6.38039C7.31757 6.82911 6.92724 7.19341 6.46096 7.43227C6.08501 7.62397 5.66982 7.72611 5.24784 7.7307V9.90961C5.41377 9.91117 5.57346 9.91195 5.73315 9.91195C5.77112 9.91195 5.82768 9.90805 5.88127 9.88101C5.92081 9.86086 5.95252 9.83133 5.97502 9.79336C5.99049 9.76617 6.00377 9.73367 6.01299 9.69648C6.05011 9.55145 6.10806 9.41255 6.18502 9.28414C6.42065 8.8857 6.85393 8.51445 7.36096 8.28039C7.87956 8.04008 8.41127 7.9718 8.81909 8.09273C8.88659 8.11289 8.97487 8.12992 9.06409 8.08492C9.12065 8.05539 9.16799 8.00664 9.21674 7.92445C9.31127 7.76086 9.40581 7.60039 9.46784 7.4943C9.53143 7.38805 9.62596 7.22836 9.72752 7.06102C9.85237 6.85023 9.76706 6.72383 9.6794 6.63695C9.3769 6.33789 9.18081 5.83945 9.14127 5.26898Z" fill="#5A5A5A"/>
</g>
<defs>
<clipPath id="clip0_1_4654">
<rect width="10" height="10" fill="white"/>
</clipPath>
</defs>
</svg>

    )
}

export default Global