import axios from '../api/axios';
import config from '../config';

const REGION_URL = '/region';
const CITY_URL = '/city';
const Nationality_URL = '/nationalities';
const BracnhList_URL = '/branches/list';
const BracnhListRegion_URL = '/branches';
const Municipality_URL = '/municipalities';
const MunicipalityByRegion_URL = '/municipalities/region/';
const SubMunicipality_URL = '/submunicipalities/municipality/';
const Type_URL = '/corporates/types';
const CAT_URL = '/corporates/categories';

const getRegion = () => {
  return axios.get(config.API_URL + REGION_URL, { headers: { 'Content-Type': 'application/json' }});
};

const getCityById = (id) => {
  return axios.get(CITY_URL + `/region/${id}`, {
    'Content-Type': 'application/json',
  });
};

const postRegion = (data) => {
  return axios.post(config.API_URL + REGION_URL, data, { headers: { 'Content-Type': 'application/json' }});
};

const getNationality = () => {
  return axios.get(config.API_URL +Nationality_URL, { headers: { 'Content-Type': 'application/json' }});
};

const getBranchList = () => {
  return axios.post(config.API_URL + BracnhList_URL, { headers: { 'Content-Type': 'application/json' }});
};

const getMunicipality = () => {
  return axios.get(config.API_URL + Municipality_URL, { headers: { 'Content-Type': 'application/json' }});
};
const getMunicipalityByRegion = (id) => {
  return axios.get(config.API_URL + MunicipalityByRegion_URL + id, { headers: { 'Content-Type': 'application/json' }});
};

const getSubMunicipality = (id) => {
  return axios.get(config.API_URL + SubMunicipality_URL + id, {
    'Content-Type': 'application/json',
  });
};

const getType = () => {
  return axios.get(config.API_URL + Type_URL, {'Content-Type': 'application/json'})
}

const getCategory = () => {
  return axios.get(config.API_URL + CAT_URL, {'Content-Type': 'application/json'})
}

const getBranchListByRegion = (regionId) => {
  return axios.get(config.API_URL +BracnhListRegion_URL +"/region/"+regionId, { headers: { 'Content-Type': 'application/json' }});
};

// const getCategories = () => {
//   return axios.get()
// }

const regionService = {
  getRegion,
  getCityById,
  postRegion,
  getNationality,
  getBranchList,
  getMunicipality,
  getMunicipalityByRegion,
  getSubMunicipality,
  getBranchListByRegion,
  getType,
  getCategory
};

export default regionService;
