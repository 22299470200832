import Global from '../../assets/svgs/Global';
import SmallCalendarSvg from '../../assets/svgs/SmallCalendar';
import { Swiper, SwiperSlide } from 'swiper/react';
import './styles.css';
import moment from 'moment';

const DateOfExam = ({ selectedBook, paymentInfo }) => {
//   console.log('selectedBook: sche ',selectedBook)
  return (
    <div>
      <h3 className="font-semibold text-xl">Date Of Exam</h3>
      <div className="mt-4">
        {/* <div className="flex items-center my-2">
          <div className="flex flex-col py-1 px-8 rounded-lg border border-dashed border-primary bg-blue-100 text-primary items-center justify-center">
            <div>
              <SmallCalendarSvg />
            </div>
            <p className="text-sm mt-1">
              {moment(selectedBook?.schedule?.createdAt).format('MMM')}
            </p>
            <p className="text-xl font-bold">
              {moment(selectedBook?.schedule?.createdAt).format('DD')}
            </p>
          </div>
          <div className="bg-pure rounded-lg py-4 px-6 flex items-center gap-4 border border-gray-200">
           
            <div className="w-1 h-10 bg-primary rounded-md"></div>
            <div className="flex flex-col gap-1">
              <p className="text-sm text-grayText">
                tesy{selectedBook?.schedule?.course?.name}
              </p>
              <div className="flex items-center gap-1">
                <div>
                  <Global />
                </div>
                <span className="text-[10px] text-grayText">
                  arab{selectedBook?.schedule?.course?.language?.nameEn}
                </span>
              </div>
            </div>
          </div>
        </div> */}

        <div className='flex items-center' >
                    <div className="flex flex-col py-1 sm:px-8 px-4 rounded-lg border border-dashed border-primary bg-blue-100 text-primary items-center justify-center">
                        <div>
                            <SmallCalendarSvg />
                        </div>
                        <p className="text-sm mt-1"> {moment(selectedBook?.schedule?.createdAt).format('MMM')}</p>
                        <p className="text-xl font-bold"> {moment(selectedBook?.schedule?.createdAt).format('DD')}</p>
                    </div>
                    <div className="bg-pure rounded-lg py-4 sm:px-6 px-3 flex items-center lg:gap-20 md:gap-12 sm:gap-4 gap-2 border border-gray-200">
                        <div className='flex items-center sm:gap-4 gap-2'>
                            {/* <div className="text-grayText font-bold sm:text-xl text-base">
                                09 am
                            </div> */}
                            <div className="w-1 h-10 bg-primary rounded-md"></div>
                            <div className="flex flex-col gap-1">
                                <p className="sm:text-sm text-xs text-grayText">{selectedBook?.schedule?.course?.name}</p>
                                <div className="flex items-center gap-1">
                                    <div>
                                        <Global />
                                    </div>
                                    <span className="text-[10px] text-grayText">{selectedBook?.schedule?.course?.language?.nameEn}</span>
                                </div>
                            </div>
                        </div>
                        <div className='text-dark font-bold sm:text-2xl text-lg'>
                            <p>{paymentInfo.total}<span className='text-sm font-normal text-grayText'>/SAR</span></p>
                        </div>
                    </div>
                </div>
      </div>
    </div>
  );
};

export default DateOfExam;
