import axios from '../api/axios';
import config from '../config';

const API_URL = '/uploader';

const postUpload = (file, setProgress) => {
  return axios.post(config.API_URL + API_URL, file, { headers: {'Content-Type': `multipart/form-data`, 
  }, 
  onUploadProgress: progressEvent => {
    let percentComplete = progressEvent.loaded / progressEvent.total
    percentComplete = parseInt(percentComplete * 100);
    // console.log('percentComplete', percentComplete);
    setProgress && setProgress(percentComplete);
  }
  });
};

const uploaderService = {
    postUpload,
};

export default uploaderService;
