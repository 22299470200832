import { useBookRetakeContext } from "../../context/BookRetakeContext";

const Stepper = () => {
    const { steps , currentStep , setCurrentStep , complete , setComplete } = useBookRetakeContext();

    return (
        <div className="flex items-center justify-between w-full">
        {steps?.map((step, i) => (
            <div
                key={i}
                className={`step-item ${currentStep === i + 1 && "bookRetakeActiveStep"} ${
                (i + 1 < currentStep || complete) && "complete"
                } `}
            >
                <div className="step">
                {i + 1 < currentStep || complete ? <div className="sm:text-xl text-lg">
                    <i className="uil uil-check"></i>
                </div> : i + 1}
                </div>
                <p className="text-gray-500 mt-2 md:text-[15px] sm:text-sm text-xs">{step}</p>
            </div>
            ))}
        </div>
    );
};

export default Stepper;



// <>
// <div className="flex justify-between">
//     {steps?.map((step, i) => (
//     <div
//         key={i}
//         className={`step-item ${currentStep === i + 1 && "activeStep"} ${
//         (i + 1 < currentStep || complete) && "complete"
//         } `}
//     >
//         <div className="step">
//         {i + 1 < currentStep || complete ? <i className="uil uil-check"></i> : i + 1}
//         </div>
//         <p className="text-gray-500">{step}</p>
//     </div>
//     ))}
// </div>
// {!complete && (
//     <button
//     className="btn"
//     onClick={() => {
//         currentStep === steps.length
//         ? setComplete(true)
//         : setCurrentStep((prev) => prev + 1);
//     }}
//     >
//     {currentStep === steps.length ? "Finish" : "Next"}
//     </button>
// )}
// </>


//  <button
//     className="btn"
//     onClick={() => {
//         currentStep === steps.length
//         ? setComplete(true)
//         : setCurrentStep((prev) => prev + 1);
//     }}
//     >
//     {currentStep === steps.length ? "Finish" : "Next"}
//     </button>
