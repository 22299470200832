import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import DatePicker from '@deskpro/react-datepicker-hijri';
import '@deskpro/react-datepicker-hijri/dist/react-datepicker.css';
import Calendar from '../../assets/svgs/light.svg';
import moment from 'moment-hijri';
export const GenericDatePicker = ({
  control,
  name,
  label,
  placeholderText,
}) => {
  const [isHijri, setIsHijri] = useState(true);
  const [calendarTitle, setCalendarTitle] = useState('Goregaon');

  return (
    <div className=" flex flex-col text-sm font-medium">
      {React.isValidElement(label) ? (
        label
      ) : (
        <span className="text-sm font-medium text-[#141522] mb-1">{label}</span>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          // console.log('Date.now()',Date.now().to)
          return (
            <div className={`flex flex-row items-center justify-between px-[20px] h-[50px] border rounded ${error?.message ? "border-[red]" : "border-[rgb(207 207 207 / var(--tw-border-opacity))]"}`}>
              <DatePicker
                placeholderText={placeholderText}
                // showMonthDropdown
                // showYearDropdown
                // dropdownMode="select"
                calendar={isHijri === true ? 'hijri' : 'goregaon'} //"hijri" //goregaon
                className={
                  'text-[#262D33] text-sm w-full outline-none focus:outline-none placeholder:text-[#262D33]'
                }
                selected={moment(value)}
                // name === 'DateOfBirth'
                todayButton={"Today"}
                minDate={name === 'DateOfBirth' ? 0 : Date.now()} //{Date.now()}
                maxDate={name === 'DateOfBirth' ? Date.now() : 0}
                onChange={(date) => {
                  //console.log('date ? ?? ',name, date)
                  onChange(moment(date));
                }}
              />
              <h1
                className='cursor-pointer'
                onClick={() => {
                  // console.log('isHijri: ',isHijri)
                  setCalendarTitle(isHijri === true ? 'Hijri' : 'Goregaon')
                  setIsHijri(!isHijri);
                }}> {calendarTitle}</h1>
              {/* <img src={Calendar} /> */}
            </div>
          );
        }}
      />
    </div>
  );
};

export default GenericDatePicker;
