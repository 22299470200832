import React from 'react'
import { useNavigate } from 'react-router-dom';

function UploadClosing() {

  const navigate = useNavigate();

  return (
    <div className='bg-white mt-16'>
      <div className='flex flex-cols p-5 gap-4' >
        <div >
          <span>Total cash revenue</span>
          <div className='mt-3'>
            <input
              disabled
              className="border rounded p-3 rounded w-full text-sm bg-gray-300"
              name="name"
              placeholder="0"
              type="text"
            />
          </div>
        </div>
        <div >
          <div>
            <span>Total cards revenue</span>
          </div>
          <div className='mt-3'>
            <input
              disabled
              className="border rounded p-3 rounded w-full text-sm bg-gray-300"
              name="name"
              placeholder="0"
              type="text"
            />
          </div>
        </div>
      </div>


      <div className='w-1/3 p-5'>
          <div>
            <span>Total revenue posting amount</span>
          </div>
          <div className='mt-3'>
            <input
              disabled
              className="border rounded p-3 rounded w-full text-sm bg-gray-300"
              name="name"
              placeholder="0"
              type="text"
            />
          </div>
        </div>

        <div className='mt-5 p-5 w-2/3'>
            <span  >Note</span>
            <textarea name="Note" placeholder='text ...' className="address_details_textarea" />
          </div>


     


      <div className="mt-4 mb-8 flex justify-between p-5">
        <div className="w-1/2">
          <button
            onClick={() => {
              navigate("/admin/branch-management");
            }}
            className={
              "cursor-pointer w-3/5 md:w-1/3 h-15 rounded-md border-2 border-slate-300 bg-white py-2 px-4 font-semibold text-[#333333] transition duration-200 ease-in-out hover:bg-slate-700 hover:text-white"
            }
          >
            Back To Posted Transactions
          </button>
        </div>
        <div>
          <button
            onClick={() => {

                // handleAddBranch()
              
              // setShowErrors(true)
              navigate("/admin/daily-closing/add-closing/finish")
              console.log('working good')

            }}
            className="cursor-pointer h-12 rounded-md bg-[#046F6D] py-2 px-12 font-semibold text-white transition duration-200 ease-in-out hover:bg-slate-700 hover:text-white"
          >
            Upload Receipt
          </button>
        </div>
      </div>

    </div>

  )
}

export default UploadClosing