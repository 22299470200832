import axios from '../api/axios';
import config from '../config';


const Type_URL = '/training-center/types';




const getType = () => {
  return axios.get(config.API_URL + Type_URL, {'Content-Type': 'application/json'})
}


const traningService = {
  getType,
};

export default traningService;
