import RetakeExamSteper1 from '../../../assets/images/retakeExamSteper1.png';
import AvailableExams from '../../../components/bookRetake/AvailableExams';
import Header from '../../../components/bookRetake/Header';
import InquiryForm from '../../../components/bookRetake/InquiryForm';
import PastExams from '../../../components/bookRetake/PastExams';
import Stepper from '../../../components/bookRetake/Steper';
import { useBookRetakeContext } from '../../../context/BookRetakeContext';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// import { useState } from 'react';

const BookRetake = () => {
    const { setCurrentStep, setComplete } = useBookRetakeContext();
    const [errorMessage, setErrorMessage] = useState('');
    const [studentId, setStudentId] = useState('');
    const [pastExams, setPastExams] = useState([]);
    const [availableExams, setAvailableExams] = useState([]);
    const [inquiry, setInquiry] = useState(false);
    const [selectedBook, setSelectedBook] = useState();

    useEffect(() => {
        setCurrentStep(1);
        setComplete(false);
    }, [])

    return (

        <div className='min-h-screen h-full pb-12'>
            {/* Header */}
            {/* <div>
             <Header />
            </div> */}
            <div>
                {/* Steper */}
                <div className='mt-6 md:mx-6 mx-2'>
                    <div className='bg-pure rounded-md w-full flex items-center justify-center py-4'>
                        <Stepper />
                    </div>
                </div>

                {/* Book Retake */}
                <div className='bg-pure rounded-md py-4 md:px-3 px-2 mt-4 md:mx-6 mx-2'>
                    {/* Form */}
                    <div>
                        <InquiryForm setSelectedBook={setSelectedBook} setStudentId={setStudentId} setErrorMessage={setErrorMessage} setAvailableExams={setAvailableExams} setPastExams={setPastExams} setInquiry={setInquiry} />
                    </div>

                    {/* Past Exams */}
                    {inquiry ?
                        <>
                            <div className='pt-6 mb-12'>
                                <PastExams pastExams={pastExams} />
                            </div>

                            {/* Available Exams */}
                            <div>
                                <AvailableExams studentId={studentId} setSelectedBook={setSelectedBook} availableExams={availableExams} errorMessage={errorMessage} />
                            </div>

                            <div className='bg-pure border-t border-t-gray-200 border-b border-b-gray-200 rounded-md py-4  flex items-center justify-between px-2 mt-12 mb-4'>
                        <Link to='/admin/retake-exam' className='py-2 px-8 text-dark text-sm border-2 border-gray-300 hover:bg-gray-300 hover:border-transparent duration-300 cursor-pointer rounded-md'>
                            <button>Cancel</button>
                        </Link>

                        {
                            selectedBook ? 
                            (
                        <Link to='/admin/retake-exam/book-retake/payment' state={selectedBook}
                        // onClick={() =>
                        //     setCurrentStep(prev => prev + 1)}
                        >
                            <div className='bg-primary py-2 rounded-md cursor-pointer px-12 font-semibold text-pure hover:bg-primaryHover'>
                                Next
                            </div>
                        </Link>

                            ) : 
                            <div disabled className='bg-primary py-2 rounded-md cursor-pointer px-12 font-semibold text-pure hover:bg-primaryHover'>
                            Next
                        </div>
                        }
                    </div>
                        </>
                        :
                        <></>
                    }


                  

                </div>
            </div>

        </div>
    )
}

export default BookRetake
