import React from 'react'

const Finance = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6 14.25C5.80109 14.25 5.61032 14.329 5.46967 14.4697C5.32902 14.6103 5.25 14.8011 5.25 15C5.25 15.1989 5.32902 15.3897 5.46967 15.5303C5.61032 15.671 5.80109 15.75 6 15.75H12C12.1989 15.75 12.3897 15.671 12.5303 15.5303C12.671 15.3897 12.75 15.1989 12.75 15C12.75 14.8011 12.671 14.6103 12.5303 14.4697C12.3897 14.329 12.1989 14.25 12 14.25H6Z" fill="#808191"/>
<path fillRule="evenodd" clipRule="evenodd" d="M5 4C4.20435 4 3.44129 4.31607 2.87868 4.87868C2.31607 5.44129 2 6.20435 2 7V17C2 17.7956 2.31607 18.5587 2.87868 19.1213C3.44129 19.6839 4.20435 20 5 20H19C19.7956 20 20.5587 19.6839 21.1213 19.1213C21.6839 18.5587 22 17.7956 22 17V7C22 6.20435 21.6839 5.44129 21.1213 4.87868C20.5587 4.31607 19.7956 4 19 4H5ZM19 5.5H5C4.60218 5.5 4.22064 5.65804 3.93934 5.93934C3.65804 6.22064 3.5 6.60218 3.5 7V8.25H20.5V7C20.5 6.60218 20.342 6.22064 20.0607 5.93934C19.7794 5.65804 19.3978 5.5 19 5.5ZM3.5 17V9.75H20.5V17C20.5 17.3978 20.342 17.7794 20.0607 18.0607C19.7794 18.342 19.3978 18.5 19 18.5H5C4.60218 18.5 4.22064 18.342 3.93934 18.0607C3.65804 17.7794 3.5 17.3978 3.5 17Z" fill="#808191"/>
</svg>

    )
}

export default Finance