import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { useState, useEffect } from 'react';
import moment from 'moment/moment';

const eventsData = [
    { 
        id : 1 ,
        title: 'Session Name' , 
        date : new Date('2023-01-01T05:30:00-05:00') , 
    },
    { 
        id : 2 ,
        title: 'Session Name' , 
        date : new Date('2023-01-05T11:30:00-05:00') , 
    },
    { 
        id : 3 ,
        title: 'Session Name' , 
        date : new Date('2023-01-11T11:30:00-05:00') , 
    },
    { 
        id : 4 ,
        title: 'Session Name' , 
        date : new Date('2023-01-21T12:30:00-13:00') , 
    },
    { 
        id : 5 ,
        title: 'Session Name' , 
        date : new Date('2023-01-26T08:30:00-05:00') , 
    },
    { 
        id : 6 ,
        title: 'Session Name' , 
        date : new Date('2023-01-20T03:30:00-13:00') , 
    },
    { 
        id : 7 ,
        title: 'Session Name' , 
        date : new Date('2022-12-20T12:30:00-05:00') , 
    },
    { 
        id : 8 ,
        title: 'Session Name' , 
        date : new Date('2023-02-07T12:30:00-05:00') , 
    },
  
]

const AvailableExams = ({errorMessage, availableExams, setSelectedBook, studentId}) => {
    const [selectedExam , setSelectedExam] = useState('');
    // const [errorMessage, setErrorMessage] = useState ('')
    const handleEventClick = (info) => {
        setSelectedExam(moment(info.event.start).format('MM/DD/YYYY'));
        // console.log('info ID? ',info.event.id)
        let selected = availableExams.filter((ex) => parseInt(ex.id) === parseInt(info.event.id));
        // console.log('selected? ',selected)
        if(selected.length > 0){
            setSelectedBook({...selected[0], studentId});
        }else {
            alert('Please Try Again..')
            setSelectedBook()
        }
    }

   useEffect(() => {
    
   }, [availableExams])


    return (
        <div className='pt-4'>
            <h3 className='font-semibold text-xl'>Available Exams</h3>
            {availableExams.length > 0 ? 
                  <>
                    <div>
                      <div className='mt-6 available-exams'>
                          <FullCalendar
                              plugins={[ dayGridPlugin ]}
                              initialView="dayGridMonth"
                              eventClick={handleEventClick}
                              headerToolbar={{
                                  start: 'dayGridMonth,dayGridWeek,dayGridDay',
                                  center : 'title',
                                  end : 'prev next'
                              }}
                              aspectRatio={1.5}
                              // expandRows={true}
                              events={availableExams?.map((ex) => {
                                return  { 
                                    id : ex.id ,
                                    // title: 'Session Name' , 
                                    // date : new Date('2023-01-20T03:30:00-13:00') , 
                                    title: ex?.schedule?.scheduleSession[0].name || '--', 
                                    date: new Date(moment(ex?.schedule?.scheduleSession[0].date).format('YYYY-MM-DD') + ' ' + ex?.schedule?.scheduleSession[0].from),
                                }
                              })}
                          />
                      </div>
                      <div className='pt-8'>
                          <div className='flex flex-col gap-1.5 flex-[0.4]'>
                              <label className='text-dark font-medium text-sm'>Select From Available</label>
                              <input 
                              disabled
                              type='text'
                              placeholder='Student ID / Code'
                              className='outline-none border border-gray-200 rounded-md py-2 px-3 text-sm bg-gray-100 text-dark lg:w-[35%] md:w-[70%] sm:w-[90%] w-full'
                              value={selectedExam}
                              />
                          </div>
                      </div>
                  </div>
                  </>

                
              : 
              <h1>{errorMessage}</h1>
            }
        </div>
    )
}

export default AvailableExams