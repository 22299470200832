import React from 'react';

function Footer({ t }) {
  return (
    <div className="bg-[#1A1B1D] p-5">
      <div className="grid grid-col-1 md:grid-cols-2 gap-2">
        <div className="text-[#AAAAAA] ml-20">© Al Muhaidib Platform 2022</div>

        <div className="text-white ">
          <div className="grid grid-cols-3 place-items-end mr-3">
            <div className="text-[#AAAAAA] underline">{t('FAQ')}</div>

            <div className="text-[#AAAAAA] underline">
              {t('Privacy policy')}
            </div>

            <div className="text-[#AAAAAA] underline">
              {t('Terms and laws')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
