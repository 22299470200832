import React, { useState, useEffect, useRef } from 'react';

const Stepper = ({ steps, currentStep, t, setCurrentStep }) => {
  const [newStep, setNewStep] = useState([]);
  const stepsRef = useRef();
  
  const updateStep = (stepNumber, steps) => {
    const newSteps = [...steps];
    let count = 0;
    while (count < newSteps.length) {
      //current step
      if (count === stepNumber) {
        newSteps[count] = {
          ...newSteps[count],
          highlighted: true,
          selected: true,
          completed: true,
        };
        count++;
      }

      //step completed
      else if (count < stepNumber) {
        newSteps[count] = {
          ...newSteps[count],
          highlighted: false,
          selected: true,
          completed: true,
        };
        count++;
      }
      //step pending
      else {
        newSteps[count] = {
          ...newSteps[count],
          highlighted: false,
          selected: false,
          completed: false,
        };
        count++;
      }
    }

    return newSteps;
  };

  useEffect(() => {
    const stepsState = steps.map((step, index) =>
      Object.assign(
        {},
        {
          description: step,
          completed: false,
          highlighted: index === 0 ? true : false,
          selected: index === 0 ? true : false,
        },
      ),
    );

    stepsRef.current = stepsState;
    const current = updateStep(currentStep - 1, stepsRef.current);
    setNewStep(current);
  }, [steps, currentStep]);

  const stepsDisplay = newStep.map((step, index) => {
    // console.log('asass: ',{newStep})
    return (
      <div
        key={index}
        onClick={() => {
          if(step.completed) {
            setCurrentStep(index)
          }
        }}
        className={
          index !== newStep.length - 1
            ? 'w-1/2 flex items-center '
            : 'flex items-center '
        }
      >
        <div className="relative flex flex-col items-center text-teal-600">
          <div
            className={`rounded-full transition duration-500 ease-in-out border-2 border-gray-300 h-10 w-10 flex items-center justify-center py-3  ${
              step.selected
                ? 'bg-[#046F6D] text-white font-bold border border-[#046F6D]'
                : ''
            } ${
              index === currentStep
                ? 'text-white font-bold border-2 border-solid border-green-800 '
                : ''
            }`}
          >
            {index === currentStep ? (
              <span className="rounded-full h-3 w-3 bg-[#046F6D]"></span>
            ) : (
              <></>
            )}
            {step.completed ? (
              <span className="text-white font-bold text-xl">&#10003;</span>
            ) : (
              <></>
            )}
          </div>
          <div
            className={`absolute hidden md:block top-0 text-center mt-16 w-56 text-sm font-bold ${
              step.completed ? 'text-[#046F6D]' : 'text-gray-400'
            }`}
          >
            {t(step.description)}
          </div>
        </div>
        <div
          className={`flex-auto border-t-2 transition duration-500 ease-in-out  ${
            step.completed ? 'border-[#046F6D]' : 'border-gray-300 '
          } `}
        ></div>
      </div>
    );
  });

  return (
    <div className="mx-12 p-16 block lg:flex justify-center items-center">
      {stepsDisplay}
    </div>
  );
};
export default Stepper;
