import React, { useState, useEffect } from 'react';
import PageTitle from '../../components/Typography/PageTitle';
import SectionTitle from '../../components/Typography/SectionTitle';
import {
  Table,
  TableHeader,
  TableCell,
  TableBody,
  TableRow,
  TableFooter,
  TableContainer,
  Badge,
  Avatar,
  Button,
  Pagination,
} from '@windmill/react-ui';
import { SearchIcon, TablesIcon } from '../../components/icons';
import response from './demo';
import corporateImg from '../../assets/img/buliding.svg';
import { useNavigate } from 'react-router-dom';

const response2 = response.concat([]);

function Booking() {
  const [pageTable1, setPageTable1] = useState(1);
  const [pageTable2, setPageTable2] = useState(1);

  const [query, setQuery] = useState('');

  const navigate = useNavigate();

  // setup data for every table
  const [dataTable1, setDataTable1] = useState([]);
  const [dataTable2, setDataTable2] = useState([]);

  // pagination setup
  const resultsPerPage = 10;
  const totalResults = response.length;

  // pagination change control
  function onPageChangeTable1(p) {
    setPageTable1(p);
  }

  // pagination change control
  function onPageChangeTable2(p) {
    setPageTable2(p);
  }

  useEffect(() => {
    setDataTable1(
      response.slice(
        (pageTable1 - 1) * resultsPerPage,
        pageTable1 * resultsPerPage,
      ),
    );
  }, [pageTable1]);

  useEffect(() => {
    setDataTable2(
      response2.slice(
        (pageTable2 - 1) * resultsPerPage,
        pageTable2 * resultsPerPage,
      ),
    );
  }, [pageTable2]);

  return (
    <>
      <div className="bg-[#FFFFFF] m-8 border-sm">
        <div className="p-4 pt-0">
          <div className="grid grid-cols-2">
            <div className="flex">
              <img src={corporateImg} className="w-8 mr-2" aria-hidden="true" />
              <PageTitle className="w-1/2">Booking Requests</PageTitle>
            </div>
            <div className="w-5/6"></div>
          </div>

          <div className="flex my-2">
            <div className="mx-2">
              <label className="text-gray-700 text-sm">SORT:</label>
              <select
                className="mx-1"
                onChange={(e) => setQuery(e.target.value)}
              >
                <option value="Corporate">A-Z</option>
                <option value="Student">Z-A</option>
              </select>
            </div>
            <div>
              <label className="text-gray-700 text-sm">STATUS:</label>
              <select
                className="mx-1"
                onChange={(e) => setQuery(e.target.value)}
              >
                <option value="Corporate">ALL</option>
                <option value="Student">Z-A</option>
              </select>
            </div>
            <div className="mx-2">
              <select
                className="border border-black rounded p-1"
                onChange={(e) => setQuery(e.target.value)}
              >
                <option value="Corporate">Corporate</option>
                <option value="Student">Student</option>
              </select>
            </div>
          </div>

          {/* <select className="border border-black rounded my-2" onChange={(e) => setQuery(e.target.value)}>
            <option value="Corporate">Corporate</option>
            <option value="Student">Student</option>
          </select> */}

          <TableContainer className="mb-8">
            <Table>
              <TableHeader>
                <tr className="bg-[#F7F7F7]">
                  {/* <TableCell><input type="checkbox" name="name1" />&nbsp;</TableCell> */}
                  <TableCell>ID</TableCell>
                  <TableCell>Corporate Name</TableCell>
                  <TableCell>Request Date </TableCell>
                  <TableCell>Num Of Student </TableCell>
                  <TableCell>Course Name</TableCell>
                  <TableCell>Booking type</TableCell>
                  <TableCell>Actions</TableCell>
                </tr>
              </TableHeader>
              <TableBody>
                {dataTable1.map((user, i) => (
                  <TableRow key={i}>
                    {/* <TableCell><input type="checkbox" name="name1" />&nbsp;</TableCell> */}
                    <TableCell>{i + 1}</TableCell>
                    <TableCell>
                      <div className="flex items-center text-sm">
                        <div>
                          <p className="font-semibold">{user.name}</p>
                          <p className="text-xs text-gray-600 dark:text-gray-400">
                            {user.job}
                          </p>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      <span className="text-sm">
                        {new Date(user.date).toLocaleDateString()}
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="text-sm">20 Student</span>
                    </TableCell>
                    <TableCell>
                      <span className="text-sm">Level 1&2 Aw....</span>
                    </TableCell>
                    <TableCell>
                      <span className="text-sm">Training</span>
                    </TableCell>
                    <TableCell className="grid grid-cols-1 md:grid-cols-2">
                      {/* <span className="text-sm"></span> */}
                      <Button
                        style={{ backgroundColor: '#046F6D' }}
                        className="text-white mr-3"
                      >
                        Accept
                      </Button>
                      <Button
                        style={{ backgroundColor: '#D80027' }}
                        className="bg-red-600 text-white"
                      >
                        Reject
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TableFooter>
              <Pagination
                totalResults={totalResults}
                resultsPerPage={resultsPerPage}
                onChange={onPageChangeTable1}
                style={{ color: '#718096' }}
                label="Table navigation"
              />
            </TableFooter>
          </TableContainer>
        </div>
      </div>
    </>
  );
}

export default Booking;
