import axios from 'axios';
import config from '../config';

export default axios.create({
  baseURL: config.API_URL,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin':
      'https://maps.googleapis.com/maps/api/mapsjs/gen_204?csp_test=true',
      "Access-Control-Allow-Origin": "https://server.mishari.dev",
      "Access-Control-Allow-Origin": "http://localhost:3000"
  },
});
