import axios from '../api/axios';
import config from '../config';

const API_URL = '/branches';

const createBranch = (data) => {
  return axios.post(config.API_URL + API_URL, data, { headers: {'Content-Type': 'application/json'}});
};

const getBranchById = (id) => {
  return axios.get(config.API_URL + API_URL +"/"+ id, { headers: {'Content-Type': 'application/json'}});
}

const getBranchesByRegion = (region) => {
  return axios.get(config.API_URL + API_URL + "/region/" + region)
}

const getBranchTypes = (region) => {
  return axios.get(config.API_URL + API_URL + "/types")
}

const branchService = {
    createBranch,
    getBranchById,
    getBranchesByRegion,
    getBranchTypes
};

export default branchService;
