export default function StepperControl({
  handleClick,
  currentStep,
  steps,
  otp,
  handleOTP,
}) {
  return (
    <>
      <div className="container mt-4 mb-8 flex justify-around">
        <button
          onClick={() => handleClick()}
          className={`cursor-pointer w-1/5 h-12 rounded-md border-2 border-slate-300 bg-white py-2 px-4 font-semibold uppercase text-[#333333] transition duration-200 ease-in-out hover:bg-slate-700 hover:text-white  ${
            currentStep === 1 ? ' cursor-not-allowed opacity-50 ' : ''
          }`}
        >
          {currentStep === 1 ? 'CANCEL' : 'BACK'}
        </button>

        {currentStep === steps.length - 1 && !otp ? (
          <button
            onClick={() => handleOTP()}
            className="cursor-pointer w-1/5 h-12 rounded-md bg-[#046F6D] py-2 px-4 font-semibold uppercase text-white transition duration-200 ease-in-out hover:bg-slate-700 hover:text-white"
          >
            Next
          </button>
        ) : (
          <button
            onClick={() => handleClick('next')}
            className="cursor-pointer w-1/5 h-12 rounded-md bg-[#046F6D] py-2 px-4 font-semibold uppercase text-white transition duration-200 ease-in-out hover:bg-slate-700 hover:text-white"
          >
            {currentStep === steps.length - 1 ? 'Confirm' : 'Next'}
          </button>
        )}
      </div>
      <hr className="my-8 h-px bg-gray-200 border-0"></hr>
      <div className="mb-12"></div>
    </>
  );
}
