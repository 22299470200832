import React from 'react'

const LeftArrow = () => {
    return (
<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M12.0569 8C12.0569 8.27614 11.833 8.5 11.5569 8.5H5.76399L7.91044 10.6464C8.1057 10.8417 8.1057 11.1583 7.91044 11.3536C7.71518 11.5488 7.39859 11.5488 7.20333 11.3536L4.20333 8.35355C4.00807 8.15829 4.00807 7.84171 4.20333 7.64645L7.20333 4.64645C7.39859 4.45118 7.71518 4.45118 7.91044 4.64645C8.1057 4.84171 8.1057 5.15829 7.91044 5.35355L5.76399 7.5H11.5569C11.833 7.5 12.0569 7.72386 12.0569 8Z" fill="black"/>
</svg>

    )
}

export default LeftArrow