// import ProfileCover from '../../assets/images/profileCover.jpg';
import BLogoImg from '../../assets/images/bLogoImg.jpg';


const ProfileInfo = ({headers}) => {
    // console.log('header data' , headers);
    return (
        <div className='bg-white rounded-md'>
            <div className='flex flex-col gap-5'>
                <div className='w-full'>
                    <img src='https://static.vecteezy.com/system/resources/previews/013/681/217/non_2x/abstract-elegant-dark-green-background-with-golden-line-diagonal-and-lighting-effect-sparkle-luxury-template-design-free-vector.jpg' alt="profile cover" className='w-full h-[220px] object-cover rounded-md' />
                </div>
                <div className='flex sm:flex-row flex-col sm:gap-4 relative px-4'>
                    <div className='border-[4px] border-primary rounded-lg w-[100px] h-[100px] -translate-y-12'>
                        <img src={headers?.logo} alt="" className='w-full h-full rounded-md object-cover'/>
                    </div>
                    <div className='sm:translate-y-0 -translate-y-7'>
                        <h6 className='text-xl font-semibold text-gray-600 mb-1'>{headers?.nameEn}</h6>
                        <div className='flex items-center gap-x-4 gap-y-1 text-gray-500 flex-wrap'>
                            <div className='flex items-center gap-2 text-sm'>
                                <i className="uil uil-envelope text-base"></i>
                                <span>{headers?.email}</span>
                            </div>
                            <div className='flex items-center gap-1 text-sm'>
                                <i className="uil uil-map-marker text-base"></i>
                                <span>{headers?.locationEn}</span>
                            </div>
                            <div className='flex items-center gap-2 text-sm'>
                                <i className="uil uil-calender text-base"></i>
                                <span>Joined {headers?.joinedDate}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProfileInfo;