import React, { useState } from 'react';


const AttendingsTable = () => {
    const [showItem , setShowItem] = useState(false)
    const [showTableItemMenu , setShowTableItemMenu] = useState(false);
    const [tableItemIndex , setTableItemIndex] = useState(0);

    return (
        <div className='bg-pure mt-4 md:px-2 md:border-none border  overflow-x-auto'>
            <table className="branches-table w-full text-sm text-left border ">
                <thead className="sm:text-sm text-[13px] bg-gray-100 text-dark">
                    <tr>
                        <th scope="col" className="sm:px-2 px-1 py-4">
                            Date
                        </th>
                        <th scope="col" className="sm:px-2 px-1 py-4 md:table-cell hidden">
                            Session Name
                        </th>
                        <th scope="col" className="sm:px-2 px-1 py-4 ">
                            Time
                        </th>
                        <th scope="col" className="sm:px-2 px-1 py-4">
                            Attendance
                        </th>
                        
                    </tr>
                </thead>
                <tbody >
                {
                    [].map((item ,i) => (///[...Array(8).keys()]
                        <>
                            <tr className="bg-white border-b border-t  bg-pure sm:text-sm text-[13px]">
                                <td scope="row" className="sm:px-2 px-1 py-3 "
                                >
                                    <div className='text-grayText flex items-center gap-2'>
                                        <div className='bg-gray-100 rounded-full w-[18px] h-[18px] flex items-center justify-center md:hidden  cursor-pointer text-[16px]'
                                        onClick={() => {
                                            setShowItem(prev => prev === i+1 ? 0 : i+1)
                                        }}>
                                            <i className="uil uil-angle-down"></i>
                                        </div>
                                        <p>20/12/2022</p>
                                    </div>
                                </td>
                                <td className="sm:px-2 px-1 py-3 text-dark md:table-cell hidden">
                                    Envanto App Design
                                </td>
                                <td className="sm:px-2 px-1 py-3 text-dark ">
                                    5:00 PM
                                </td>
                                
                                <td className="sm:px-2 px-1 py-3 text-dark">
                                    <div className='flex items-center justify-between'>
                                        <div className={`
                                        ${(i+1) % 2 === 0 ? 'bg-red-100 text-red-500': 'bg-green-100 text-green-500'} rounded-md sm:py-1 sm:px-6 px-3`}>
                                            {(i+1) % 2 === 0 ? 'Non' : 'Attend'}
                                        </div>
                                        <div className='cursor-pointer text-lg relative'
                                        onClick={() => {
                                            setShowTableItemMenu(prev => !prev);
                                            setTableItemIndex(i);
                                        }
                                        }
                                        >
                                            <i className="uil uil-ellipsis-h"></i>
                                            {/* Drop menu */}
                                            {
                                                showTableItemMenu && i === tableItemIndex && 
                                                <div className='absolute top-6 right-0 bg-pure rounded-md py-2 px-1 flex flex-col gap-1 z-50 w-[200px]'
                                                style={{
                                                    boxShadow : 'rgba(0, 0, 0, 0.16) 0px 1px 4px'
                                                }}
                                                >
                                                    
                                                    <div className='flex items-center gap-3 text-sm hover:bg-gray-100 py-2 rounded-md px-2'>
                                                        <i className="uil uil-user-circle text-base"></i>
                                                        <p>View Profile</p>
                                                    </div>
                                                    <div className='flex items-center gap-3 text-sm hover:bg-gray-100 py-2 rounded-md px-2'>
                                                        <i className="uil uil-edit text-base"></i>
                                                        <p>Edit Profile</p>
                                                    </div>
                                                    <div className='flex items-center gap-3 text-sm hover:bg-gray-100 py-2 rounded-md px-2'>
                                                        <i className="uil uil-repeat text-base"></i>
                                                        <p>Replace Student</p>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr className={`${showItem === i + 1 ? 'w-full' : 'hidden'} text-[13px]`}>
                                <td colSpan={6} className='table-cell '>
                                    <ul className='flex justify-between flex-col md:text-sm text-[13px]'>
                                        <li className='bg-gray-100 py-4 flex items-center justify-between   '>
                                            <div className='flex-1 pl-4 font-semibold flex items-center gap-1'>
                                                <div className='w-[7px] h-[7px] rounded-full bg-green-500'></div>
                                                <p>Session Name</p>
                                            </div>
                                            <p className='flex-1 px-4'>Envato App Design</p>
                                        </li>
                                        
                                    </ul>
                                </td>
                            </tr>
                        </>
                    ))
                }
                    
                    
                    
                    
                </tbody>
            </table>
        </div>
    )
}

export default AttendingsTable