import { useBookingManagementContext } from '../../context/BookingManagementContext'
import React from 'react'

const Tabs = ({tabs}) => {
    const { activeTab , setActiveTab } = useBookingManagementContext();

    return (
        <div className='bg-pure rounded-md sm:px-4 px-2 w-full py-3 border-b flex items-center sm:gap-8 gap-4 '
        style={{
            boxShadow : 'rgba(0, 0, 0, 0.16) 0px 1px 4px'
        }}>
            {
                tabs?.map(item => (
                    <div 
                    key={item?.id} 
                    className={`uppercase text-grayText font-medium relative sm:text-sm text-xs cursor-pointer
                    ${activeTab === item.id ? "after:content-[''] after:w-full after:h-[3px] after:bg-primary after:absolute after:top-7 after:left-0 after:rounded-lg text-primary font-semibold" : ''}
                    `}
                    onClick={() => setActiveTab(item.id)}
                    > 
                        <span>{item?.tab}</span>
                    </div>
                ))
            }
        </div>
    )
}

export default Tabs