import HeaderRight from '../../../components/global/headerRight';
import RetakeExamTable from '../../../components/retakeExam/RetakeExamTable';


const RetakeExam = () => {
    return (
        <div className='min-h-screen h-full pb-12'>
            {/* Retake Exam */}
            <div>
                <RetakeExamTable />
            </div>

        </div>
    )
}

export default RetakeExam
