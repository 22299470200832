const Info = ({ info,requestType }) => {
  // console.log('Info data' , info);

  return (
    <div>
      <div>
        <div>
          {/* {console.log('ssssss',f)} */}
          <h1 className="text-xl font-bold my-3 p-5">
            {' '}
            Booking Details
          </h1>
          <div>
            <div className="grid grid-cols-2 gap-4 p-5">
              <div>

                <div>
                  <div className="grid grid-cols-2 gap-4 p-2">
                    <div>
                      <label>Booking No</label>
                    </div>
                    <div>
                      <label className="text-gray-400">
                        {info?.id}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <div className="grid grid-cols-2 gap-4 p-2">
                    <div>
                      <label>Date</label>
                    </div>
                    <div>
                      <label className="text-gray-400">
                        {info?.date}
                      </label>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 p-5">
              <div>
              {requestType === 'Corporate' ?
               <div>
               <div className="grid grid-cols-2 gap-4 p-2">
                 <div>
                   <label>Corporate CR</label>
                 </div>
                 <div>
                   <label className="text-gray-400">
                     {info?.corporateCr}
                   </label>
                 </div>
               </div>
             </div>
             :
             <div>
             <div className="grid grid-cols-2 gap-4 p-2">
               <div>
                 <label>Student ID/Iqama</label>
               </div>
               <div>
                 <label className="text-gray-400">
                   {info?.studentId}
                 </label>
               </div>
             </div>
           </div>
            }
               
              </div>
              <div>
                <div>
                  <div className="grid grid-cols-2 gap-4 p-2">
                    <div>
                      <label>Branch</label>
                    </div>
                    <div>
                      <label className="text-gray-400">
                        {info?.branchName}
                      </label>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 p-5">
              <div>
              {requestType === 'Corporate' ?
              <div>
              <div className="grid grid-cols-2 gap-4 p-2">
                <div>
                  <label>Corporate Code</label>
                </div>
                <div>
                  <label className="text-gray-400">
                    {info?.corporateCode}
                  </label>
                </div>
              </div>
            </div>
            :
                <div>
                  <div className="grid grid-cols-2 gap-4 p-2">
                    <div>
                      <label>Student Code</label>
                    </div>
                    <div>
                      <label className="text-gray-400">
                        {info?.studentCode}
                      </label>
                    </div>
                  </div>
                </div>
              }
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>




    // <div>
    //   <div className="grid grid-cols-2 gap-4 p-2">
    //     <div>
    //       {/* Filed Name */}
    //       <label>
    //         Test
    //         </label>
    //     </div>
    //     <div>
    //       {/* Filed Value */}
    //       <label className="text-gray-400">
    //         {info?.id}
    //       </label>
    //     </div>
    //   </div>
    // </div>
  )
}

export default Info