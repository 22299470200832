import React, { useState, useCallback } from 'react';
import headerImage from '../register_header.jpg';
import logo from '../../../components/almuhaidab_logo.svg';
import logo2 from '../../../components/almuhaidab_title.svg';
import { useNavigate } from 'react-router-dom';
import Stepper from '../../../components/Stepper';
import StepperControl from '../../../components/StepperControl';
import { UseContextProvider } from '../../../context/StepperContext';

import CenterDetails from '../../../components/medical-steps/CenterDetails';
import Attachment from '../../../components/medical-steps/Attachment';
import Final from '../../../components/medical-steps/Attachment';

function StudentRegister() {
  const [currentStep, setCurrentStep] = useState(1);

  const steps = ['Center Details', 'Attachment', 'Done'];

  const displayStep = (step) => {
    switch (step) {
      case 1:
        return <CenterDetails />;
      case 2:
        return <Attachment />;
      case 3:
        return <Final />;
      default:
    }
  };

  const navigate = useNavigate();

  const handleClick = (direction) => {
    let newStep = currentStep;

    direction === 'next' ? newStep++ : newStep--;
    // check if steps are within bounds
    newStep > 0 && newStep <= steps.length && setCurrentStep(newStep);
  };

  return (
    <div className="relative">
      <div>
        <img
          className="w-full absolute top-0 left-0"
          src={headerImage}
          alt="Header image"
        ></img>
      </div>
      <div className="absolute top-0 left-0 lg:ml-24 lg:mt-10">
        <img className="inline" src={logo} alt="Logo here"></img>
        <img className="inline" src={logo2} alt="Logo here"></img>
      </div>
      <div className="absolute top-0 right-0">
        <button
          onClick={() => {
            navigate('/login');
          }}
          className="lg:mr-24 lg:mt-16 bg-[#046F6D] text-white w-36 h-10 rounded-sm"
        >
          Login
        </button>
      </div>
      <div className="flex justify-center">
        <p className="absolute text-white text-5xl mt-48">
          Training Center Registration
        </p>
      </div>
      <div className="flex justify-center">
        <div className="absolute mx-auto mt-80 pb-2 shadow-xl md:w-9/12">
          {/* Stepper */}
          <div className="rounded-xl bg-white">
            <div className="horizontal container mt-24">
              <Stepper steps={steps} currentStep={currentStep} />
            </div>

            <div className="my-10 p-10 bg-white border-t-2 border-black">
              <UseContextProvider>
                {displayStep(currentStep)}
              </UseContextProvider>
            </div>
          </div>

          {/* navigation button */}
          {currentStep !== steps.length && (
            <StepperControl
              handleClick={handleClick}
              currentStep={currentStep}
              steps={steps}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default StudentRegister;
