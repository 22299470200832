import { Badge, Dropdown, DropdownItem, Button } from '@windmill/react-ui';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import Arrow from '../../assets/svgs/caret-down-fill.svg';
import searchIcon from '../../assets/svgs/search-normal.svg';
import _ from 'lodash';
export const GenericDropdown = ({
  label,
  options,
  control,
  name,
  placeholder,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  function toggleDropdown() {
    setIsOpen(!isOpen);
  }

  const [shownOptions, setShownOptions] = useState(options);
  useEffect(() => {
    if (options.length) {
      setShownOptions(options);
    }
  }, [options]);
  const handleChange = (e) => {
    setShownOptions(
      options.filter((option) =>
        option.label
          .toLowerCase()
          .includes(e.target.value.trim().toLowerCase()),
      ),
    );
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <div className="relative">
            <div className="mb-1">
            {React.isValidElement(label) ? label : <span>{label}</span>}
            </div>
            <div
              className={
                error?.message
                  ? 'w-full h-[50px] flex flex-row justify-between items-center px-[20px] py-[16px] rounded border border-[red]'
                  : 'w-full h-[50px] flex flex-row justify-between items-center px-[20px] py-[16px] rounded border border-[#CFCFCF]'
              }
              onClick={toggleDropdown}
            >
              <span>
                {options.find((option) => value === option.value)?.label ??
                  placeholder}
              </span>
              <img src={Arrow} />
            </div>
            <Dropdown
              className="w-full z-50 max-h-[200px] overflow-scroll"
              isOpen={isOpen}
              onClose={_.debounce(() => setIsOpen(false), 300)}
            >
              <div className=" px-2 py-1 border flex flex-row justify-between items-center border-[#F5F5F7] h-[35px] rounded">
                <input
                  onChange={handleChange}
                  className="h-full outline-none border-none"
                  type="text"
                />
                <img className={'w-[16px] h-[16px] '} src={searchIcon} />
              </div>
              {shownOptions.map((option) => (
                <DropdownItem
                  className="outline-none border-none focus:outline-none"
                  key={option.value}
                  onClick={() => {
                    onChange(option.value);
                    // console.log(option.value);
                    setIsOpen(false);
                  }}
                >
                  {option.label}
                </DropdownItem>
              ))}
            </Dropdown>
          </div>
        );
      }}
    />
  );
};

export default GenericDropdown;
