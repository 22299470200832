import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './assets/css/tailwind.output.css';
import App from './App';
import { SidebarProvider } from './context/SidebarContext';
import { Windmill } from '@windmill/react-ui';
import ThemedSuspense from './components/Dashboard/ThemedSuspense';
import './i18n/config';
import './utilities.css';
// import 'swiper/css';
// import 'swiper/css/pagination';
import DrawerContextProvider from './context/DrawerContext';
import NewCourseContextProvider from './context/NewCourseSteperContext';
import BookingManagementContextProvider from './context/BookingManagementContext';
import BookRetakeContextProvider from './context/BookRetakeContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <React.Fragment>
    <SidebarProvider>
      <DrawerContextProvider>
        <NewCourseContextProvider>
          <BookRetakeContextProvider>
            <BookingManagementContextProvider>
              <Suspense fallback={<ThemedSuspense />}>
                <Windmill usePreferences>
                  <App />
                </Windmill>
              </Suspense>
            </BookingManagementContextProvider>
          </BookRetakeContextProvider>
        </NewCourseContextProvider>
      </DrawerContextProvider>
    </SidebarProvider>
  </React.Fragment>
  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
