import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CourseImage from '../../assets/img/CourseImage.svg';
import clock from '../../assets/img/clock.svg';
import clockhistory from '../../assets/img/clock-history.svg';
import user from '../../assets/img/user.svg';
import book from '../../assets/img/book.svg';
import presonImage4 from '../../assets/img/presonImage4.svg';
import playList from '../../assets/img/fontisto_play-list.svg';
import video from '../../assets/img/video.png';
import stop from '../../assets/img/gg_play-pause-o.svg';
import play from '../../assets/img/ant-design_play-circle-filled.svg';
import locked from '../../assets/img/locked.svg';
import About from '../../components/course-detail/About';
import Content from '../../components/course-detail/Content';
import Review from '../../components/course-detail/Review';

function CourseDetails() {
  const param = useParams();
  const navigate = useNavigate();

  return (
    <>
      <div className="bg-[#FFFFFF] m-8 border-sm">
        <div className="flex flex-wrap m-4 mt-8">
          <div className="w-full md:w-2/3">
            <img src={video}></img>
            <br></br>
            <br></br>
            <p>Master</p>
            <br></br>
            <h1 className="text-4xl font-bold">
              Animation is the key successfull UI/UX Design
            </h1>
            <br></br>
            <div className="flex justify-between">
              <img src={presonImage4}></img>
              <p className="mt-2 font-bold">Roynaldo Jr</p>
              <p className="text-[#9C9CA4] mt-2">
                {' '}
                | UI/UX Design . Apps Design
              </p>
              <p className="w-1/3"></p>
            </div>
            <br></br>
            <div className="flex justify-between">
              <img src={user} className="w-5"></img>
              <p className="mt-1">500 Student</p>
              <img src={book} className="w-5 mt-1"></img>
              <p className="mt-1">5 Modules</p>
              <img src={clockhistory} className="mt-1 w-5"></img>
              <p className="mt-1">1h 30m</p>
              <p className="w-1/3"></p>
            </div>
            <br></br>
            <div className="my-6 w-full">
              <div className="max-w-screen-xl px-4 py-3 mx-auto md:px-6">
                <div className="flex items-center">
                  <ul className="flex flex-row mt-0 mr-6 space-x-16 text-lg font-medium">
                    <li>
                      <p className="active:underline" aria-current="page">
                        About
                      </p>
                    </li>
                    <li>
                      <p className="text-[#9C9CA4] hover:underline">Content</p>
                    </li>
                    <li>
                      <p className="text-[#9C9CA4] hover:underline">Tools</p>
                    </li>
                    <li>
                      <p className="text-[#9C9CA4] hover:underline">Review</p>
                    </li>
                  </ul>
                </div>
              </div>
              <hr></hr>
            </div>
            {/* <About /> */}
            <Content />
            {/* <Review /> */}
          </div>
          <div className="w-full md:w-1/3">
            <div className="ml-5">
              <div className="p-2 max-w-sm bg-gray-100 border border-gray-200 rounded-lg shadow-md  dark:border-gray-700 mt-5">
                <div className="flex">
                  <div className="flex justify-between">
                    <img className="mr-3 w-6" src={playList} />
                    <h1 className="font-bold text-sm"> 30 lessons</h1>
                  </div>

                  <div className="ml-20">
                    <div>
                      <div className="flex">
                        <img src={clock} />
                        <p className="flex">1h 30m</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-4 flex bg-white border border-gray-200 rounded-lg shadow-md  dark:border-gray-700 p-2 mt-5">
                  <div className="mr-2 mt-1">1</div>
                  <div className="flex">
                    <img src={stop} />
                    <h1 className="mt-1 ml-2"> Intro</h1>
                  </div>
                  <div className="ml-36 mt-1">
                    <h1>10:00 MIN</h1>
                  </div>
                </div>

                <div className="mt-4 flex bg-white border border-gray-200 rounded-lg shadow-md  dark:border-gray-700 p-2">
                  <div className="mr-2 mt-3">2</div>
                  <div className="flex">
                    <img src={play} />
                    <h1 className="mt-1 ml-2"> Basic introduction...</h1>
                  </div>
                  <div className="ml-20 mt-1">
                    <h1>10:00 MIN</h1>
                  </div>
                </div>

                <div className="mt-4 flex bg-white border border-gray-200 rounded-lg shadow-md  dark:border-gray-700 p-2">
                  <div className="mr-2 mt-3">3</div>
                  <div className="flex">
                    <img src={locked} />
                    <h1 className="mt-1 ml-2"> Basic introduction...</h1>
                  </div>
                  <div className="ml-20 mt-1">
                    <h1>10:00 MIN</h1>
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-3 mt-10 ml-5">
                  <div>
                    <button
                      onClick={() => {
                        navigate(`/corporate/courses/confirm/${param.id}`);
                      }}
                      className="bg-[#046F6D] text-white font-bold p-3 rounded-lg w-full"
                    >
                      {' '}
                      Book Now{' '}
                    </button>
                  </div>

                  <div className="flex mt-5 ml-5">
                    <h1 className="flex font-bold text-2xl">180 </h1>
                    <p className="flex mt-2">/SAR</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CourseDetails;
