import React, { useState, useEffect } from 'react';
import PageTitle from '../../../components/Typography/PageTitle';
import {
  Table,
  TableHeader,
  TableCell,
  TableBody,
  TableRow,
  TableFooter,
  TableContainer,
  Badge,
  Avatar,
  Button,
  ModalFooter,
  ModalHeader,
  ModalBody,
  Pagination,
  Modal,
} from '@windmill/react-ui';
import corporateImg from '../../../assets/img/buliding.svg';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import more from '../../../assets/img/more.svg';
import config from '../../../config';

function AmanaBatch() {
  const [batchBody, setBatchBody] = useState([]);
  const [allData, setAllData] = useState([])

  const navigate = useNavigate();
  const params = useParams();
  let idParam = params.id;

  // pagination setup
  const resultsPerPage = 10;
  const [pageTable1, setPageTable1] = useState(1);
  function onPageChangeTable1(p) {
    setPageTable1(p);
  }

  async function getAllList() {
    let data;
    data = await axios.get(config.API_URL + '/finance/municipality-invoicing/by-batch/' + idParam, {}).then((res) =>{
        setBatchBody(res.data);
    }).catch((err)=>{
        setBatchBody([])
    });
  }
  

  useEffect(() => {
    getAllList();
  }, []);


  return (
    <>
      <div className="bg-[#FFFFFF] m-8 border-sm">
        <div className="p-4 pt-0">
          <div className="flex justify-between">
            <img src={corporateImg} className="w-8" aria-hidden="true" />
            <PageTitle className="w-1/2"> Amana Batch {idParam}</PageTitle>
            <div className="w-4/6"></div>
          </div>


          <TableContainer className="mb-8">
            <Table>
              <TableHeader>
                <tr className="bg-[#F7F7F7]">
                  <TableCell>ID</TableCell>
                  <TableCell>Student Full name</TableCell>
                  <TableCell>Exam title</TableCell>
                  <TableCell>Branch name</TableCell>
                  <TableCell>Attending date</TableCell>
                  <TableCell>Amana fees</TableCell>
                  <TableCell>Batch Number</TableCell> 
                </tr>
              </TableHeader>
              <TableBody>
                {batchBody?.map((score, i) => (
                  <TableRow key={i}>
                    <TableCell>
                    <div className="flex items-center text-sm">
                        <div className='flex space-x-4'>
                          <p className="font-semibold">{score?.student?.id}</p>
                        </div>
                      </div>
                  
                     </TableCell>
                    <TableCell>
                      <div className="flex items-center text-sm">
                        <div>
                          <p className="font-semibold">{score?.student?.nameEn}</p>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="flex items-center text-sm">
                        <div>
                          <p className="font-semibold">{score?.booking?.schedule?.course?.name}</p>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      {/* <span className="text-sm">
                        {score?.student?.branch?.name}
                      </span> */}
                      <div className="flex items-center text-sm">
                        <div>
                          <p className="font-semibold">{score?.student?.branch?.name}</p>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                    <div className="flex items-center text-sm">
                        <div>
                          <p className="font-semibold">{moment(score?.booking?.schedule?.scheduleSession[0]?.date).format('DD MMM YYYY')}</p>
                        </div>
                      </div>
                      {/* <span className="text-sm">{moment(score?.booking?.schedule?.scheduleSession[0]?.date).format('DD MMM YYYY')}</span> */}
                    </TableCell>
                   
                    <TableCell>
                    <div className="flex items-center text-sm">
                        <div>
                          <p className="font-semibold">{score?.booking?.schedule?.course?.fee[0]?.municipalityAmount}</p>
                        </div>
                      </div>
                      {/* <span className="text-sm">{score?.booking?.schedule?.course?.fee[0]?.municipalityAmount}</span> */}
                    </TableCell>
                    <TableCell>
                    <div className="flex items-center text-sm">
                        <div>
                          <p className="font-semibold">{score?.batch}</p>
                        </div>
                      </div>
                      {/* <span className="text-sm">{score?.booking?.schedule?.course?.fee[0]?.municipalityAmount}</span> */}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TableFooter>
              <Pagination
                totalResults={batchBody?.length}
                resultsPerPage={resultsPerPage}
                onChange={onPageChangeTable1}
                style={{ color: '#718096' }}
                label="Table navigation"
              />
            </TableFooter>
          </TableContainer>
          <div className="mt-4 mb-8 flex justify-between">
            <div className="w-1/2">
            <button
               onClick={()=> navigate('/admin/amana-invoice')}
                className="cursor-pointer h-12 rounded-md bg-[#046F6D] py-2 px-12 font-semibold text-white transition duration-200 ease-in-out hover:bg-slate-700 hover:text-white"
              >
                Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AmanaBatch;
