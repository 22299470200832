import React, { useState } from 'react';

import { Controller } from 'react-hook-form';
import Dropzone from 'react-dropzone';
import uploaderService from '../../services/uploaderService';
import UploadFileImage from '../../assets/img/Line.svg';
// import VideoSvg from '../../assets/img/Video';
// import DeleteSvg from '../../assets/img/Delete';
// import WordSvg from '../../assets/img/Word';
// import ExcelSvg from '../../assets/img/Excel';
// import PowerPointSvg from '../../assets/img/PowerPoint';
import close from '../../assets/svgs/close.svg'
import { FileIcon, defaultStyles } from "react-file-icon";

function bytesToUnit(bytes, decimals = 2) {
  let units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB']

  let i = 0

  for (i; bytes > 1024; i++) {
    bytes /= 1024;
  }

  return parseFloat(bytes.toFixed(decimals)) + ' ' + units[i]
}

export const AttachmentComponent = ({
  control,
  name,
  inputName,
  setAllData,
  allData,
  allowedFiles,
  accept,
  multiple
}) => {
  const [uploadedFiles, setUploadedFiles] = useState({});
  const [uploadFilesProgress, setUploadFilesProgress] = useState({});

  function setProgress(file) {
    return (progress) => {
      if (multiple && false) {
        if (Array.isArray(uploadFilesProgress[file])) {
          console.log('set prog arr');
          setUploadFilesProgress({ ...uploadFilesProgress, [file]: uploadFilesProgress[file].push(progress) });
        } else {
          console.log('set prog else');
          setUploadFilesProgress({ ...uploadFilesProgress, [file]: [progress] });
        }
      } else {
        setUploadFilesProgress({ ...uploadFilesProgress, [file]: progress });
      }
      //   setUploadFilesProgress({ ...uploadFilesProgress, [file]: progress });
      // console.log('uploadFilesProgress', uploadFilesProgress)
    }
  }

  const handleChangeFile = (file, name) => {
    // console.log(file[0]);
    console.log('1111file', file);
    const data = {
      dataType: "arkan",
      file: file[0]
    }
    // console.log("Here we go 1 ",file[0])
    // console.log("Here we go 2 ",name)
    // console.log("Here we go 3 ",Array.isArray(uploadedFiles[name]))
    // console.log("Here we go 4 ",uploadedFiles[name])
    if (multiple) {
      //   console.log("I am in multiple")
      if (Array.isArray(uploadedFiles[name])) {
        // console.log("I am multiple files")
        // console.log('arr multiple');
        let tempArray = uploadedFiles[name] || [];
        // console.log('tempArrayL: ',tempArray);
        tempArray.push(file[0])
        setUploadedFiles({ [name]: tempArray });
      } else {
        //   console.log("I am single file")
        //         console.log('arr else single');
        setUploadedFiles({ ...uploadedFiles, [name]: [file[0]] });
      }
      // setUploadedFiles({ ...uploadedFiles, [name]: file[0].name });
    } else {
      //   console.log("I am here now !")
      // console.log('ellls');
      setUploadedFiles({ ...uploadedFiles, [name]: file[0] });
    }
    // setUploadedFiles({ ...uploadedFiles, [name]: file[0].name });
    // console.log('2222: ', data);
    uploaderService.postUpload(data, setProgress(name)).then((result) => {

      //   console.log('result.data: ', result.data)
      //   setAllData({ ...allData, [name]: result.data.id });
      if (multiple) {
        let finalData = allData[name] || [];
        // console.log('all data: multiple')
        if (Array.isArray(finalData)) {
          // console.log('all data: Array')
          finalData.push(result.data.id)
          setAllData({ ...allData, [name]: finalData });
        } else {
          // console.log('all data: else')
          setAllData({ ...allData, [name]: [result.data.id] });
        }
      } else {
        // console.log('arr else');
        setAllData({ ...allData, [name]: result.data.id });
      }
    }).catch((err) => {
      //   alert('sdc')
      //   console.log('error');
    });
    // console.log('33');
    // console.log('asasasas: ', allData)
  };

  if (control) {
    return (
      <div className="flex flex-col text-sm font-medium ">
        {/* {React.isValidElement(label) ? (
          label
        ) : (
          <span className="text-sm font-medium text-[#141522] mb-1">{label}</span>
        )} */}
        <Controller
          key={inputName}
          control={control}
          name={inputName}
          render={({ field: { value: fieldValue, onChange } }) => {
            return <div className='flex w-full flex-col'>
              <div className=''>
                {
                  uploadedFiles[name] ? (
                    <AttachmentPreview
                      fileName={uploadedFiles[name].name}
                      fileSize={uploadedFiles[name].size}
                      progress={uploadFilesProgress[name]}
                      onRemove={() => {
                        setUploadedFiles({ ...uploadedFiles, [name]: null })
                        setAllData({ ...allData, [name]: null })
                      }}
                    />
                  ) : (<AttachmentDropzone
                    multiple={multiple}
                    accept={accept}
                    onDrop={(acceptedFiles) => {
                      handleChangeFile(acceptedFiles, [name]);
                    }}
                    allowedFiles={allowedFiles}
                  />)
                }
              </div>
              {/* <Dropzone
                key={data.inputName}
                onDrop={(acceptedFiles) => {
                  let tempProg = progress;
                  tempProg[[data.inputName]] = 30;
                  setProgress(tempProg);

                  let tempShow = showProgress;
                  tempShow[[data.inputName]] = true;
                  setShowProgress(tempShow)
                  onChange(acceptedFiles[0])
                  // preventDropOnDocument = true
                  handleChangeFile(acceptedFiles, data.inputName);

                }}
              >
                {({ getRootProps, getInputProps }) => (
                  // bg-[#fff] flex items-center border-dashed border-2 border-[#ADADAD] w-[162px] h-[130px]
                  <section className={data.inputStyle}>
                    <div
                      className="my-5 hover:cursor-pointer w-full"
                      {...getRootProps()}
                    >
                      <input {...getInputProps()} />
                      <img

                        src={data.targetdLabel ? UploadFileIcon : UploadFileImage}
                        alt="Upload"
                        className="mx-auto cursor-pointer"
                      />
                      <p className={data.targetedLabelStyle}>
                        {data.targetdLabel ?? "Upload"}
                      </p>
                    </div>
                  </section>
                )}
              </Dropzone> */}

            </div>
          }}
        />
      </div>
    );
  } else {
    return (
      <div className=''>
        <AttachmentDropzone
          multiple={multiple}
          accept={accept}
          onDrop={(acceptedFiles) => {
            handleChangeFile(acceptedFiles, [name]);
          }}
          allowedFiles={allowedFiles}
        />
        {/* {console.log('uploadFilesProgress: ',uploadedFiles[name],Array.isArray(uploadedFiles[name]),uploadFilesProgress,uploadedFiles)} */}
        {
          uploadedFiles[name] && multiple === true ? (
            <>
              {console.log('multiple/true', uploadedFiles[name])}
              {uploadedFiles[name].map((item, i) => (
                <AttachmentPreview
                  key={i}
                  fileName={uploadedFiles[name][i].name}
                  fileSize={uploadedFiles[name][i].size}
                  progress={i == uploadedFiles[name].length -1? uploadFilesProgress[name]: 100}
                  // onRemove={() => {
                  //   console.log('uploadedFiles[name]', uploadedFiles[name])
                  //   let temp = uploadedFiles[name];
                  //   let tempData = allData[name];
                  //   console.log('temp: ', typeof temp, temp)
                  //   console.log('tempData: ', typeof tempData, tempData)
                  //   temp = temp.filter((te, ind) => ind !== i);//.filter((te) => te !== item);
                  //   tempData = tempData.filter((te, ind) => ind !== i);//.splice(1,i);
                  //   console.log('temp: after ', typeof temp, temp)
                  //   console.log('tempData: ', typeof tempData, tempData)
                  //   setUploadedFiles({ [name]: temp })
                  //   setAllData({ ...allData, [name]: tempData })
                  // }}
                  onRemove={() => {
                    console.log('uploadedFiles[name]', uploadedFiles[name])
                    console.log('allData[name]', allData[name])
                    let temp = uploadedFiles[name];
                    let tempData = allData[name];
                    console.log('temp: ', typeof temp, temp)
                    console.log('tempData: ', typeof tempData, tempData)
                    temp = temp.filter((te, ind) => ind !== i);//.filter((te) => te !== item);
                    tempData = tempData.filter((te, ind) => ind !== i);//.splice(1,i);
                    console.log('temp: after ', typeof temp, temp)
                    console.log('tempData: ', typeof tempData, tempData)
                    setUploadedFiles({ [name]: temp })
                    setAllData({ ...allData, [name]: tempData })
                  }}
                />
                // <div key={i} className='flex flex-row mt-1 space-between rounded-lg bg-[#F4F5F6] w-full p-4'>

                //   <p className='flex-1'>
                //     {uploadedFiles[name][i].name}
                //   </p>
                //   <div
                //     className='rounded-full px-2.5 py-1 cursor-pointer text-red-500 font-bold'
                //     onClick={() => {
                //       console.log('uploadedFiles[name]', uploadedFiles[name])
                //       let temp = uploadedFiles[name];
                //       let tempData = allData[name];
                //       console.log('temp: ',typeof temp, temp)
                //       console.log('tempData: ',typeof tempData, tempData)
                //       temp = temp.filter((te, ind) => ind !== i);//.filter((te) => te !== item);
                //       tempData = tempData.filter((te, ind) => ind !== i);//.splice(1,i);
                //       console.log('temp: after ',typeof temp, temp)
                //       console.log('tempData: ',typeof tempData, tempData)
                //       setUploadedFiles({ [name]: temp })
                //       setAllData({ ...allData, [name]: tempData })
                //     }}
                //   >
                //     <img src={close} />
                //     {/* <span className='text-red-500'>X</span> */}
                //   </div>
                // </div>
              ))}
            </>
          ) : uploadedFiles[name] && (
            <AttachmentPreview
              fileName={uploadedFiles[name].name}
              fileSize={uploadedFiles[name].size}
              progress={uploadFilesProgress[name]}
              onRemove={() => {
                setUploadedFiles({ ...uploadedFiles, [name]: null })
                setAllData({ ...allData, [name]: null })
              }}
            />
          )
        }
      </div>
    );
  }

};

function AttachmentDropzone({
  multiple,
  accept,
  onDrop,
  allowedFiles
}) {
  return (
    <Dropzone
      multiple={multiple || false}
      accept={accept || ''}
      onDrop={onDrop}
    >
      {({ getRootProps, getInputProps }) => (
        <section className="bg-[#F4F5F6] border-dashed border-2 border-[#ADADAD] rounded-lg w-full">
          <div
            className="my-5 hover:cursor-pointer"
            {...getRootProps()}
          >
            {/* <p className='px-2'>{label || ''}</p> */}
            <input {...getInputProps()} />
            <img
              src={UploadFileImage}
              alt="Upload"
              className="mx-auto"
            />
            <p className="text-[#777E91] text-center text-xs">
              {allowedFiles || 'PNG, JPG, JPEG, PDF. Max 2Mb.'}
            </p>
          </div>
        </section>
      )}
    </Dropzone>
  )
}

function AttachmentPreview({
  fileName,
  fileSize,
  progress,
  onRemove
}) {
  return (
    <>
      <div className='flex flex-row mt-1 space-between rounded-lg bg-[#F4F5F6] w-full p-2'>
        <div className='w-4 m-2'>
          <FileIcon extension={fileName.split('.').pop()} {...defaultStyles[fileName.split('.').pop()]} />
        </div>
        <div className='flex-1 flex-col my-2'>
          <p className='text-[0.5rem]'>
            {fileName}
          </p>
          <p className='text-[0.5rem]'>
            {bytesToUnit(fileSize)}
          </p>
        </div>
        <div
          className='rounded-full m-2 cursor-pointer text-red-500 font-bold'
          onClick={onRemove}
        >
          <img src={close} />
          {/* <span className='text-red-500'>X</span> */}
        </div>
      </div>

      {
        progress != 100 && (
          <div
            className='bg-green-500 h-1 rounded-full'
            style={{ width: progress + '%' }}
          >
          </div>
        )
      }
    </>
  )
}

export default AttachmentComponent;
