import AttendingsTable from './AttendingsTable';
import StudentsList from './StudentsList'

const Attendings = () => {
    

    return (
        <div className='bg-pure mt-8'>
            <div className='border-b-2 border-b-gray-300 pb-1 font-semibold text-sm'>
                <span>Attendins</span>
            </div>
            <div className='flex lg:flex-row flex-col gap-4 py-4'>
                {/* <div className='flex-[0.22]'>
                    <StudentsList />
                </div> */}
                <div className='flex-[1]'>
                    <AttendingsTable />
                </div>
            </div>
        </div>
    )
}

export default Attendings